import styled, { keyframes } from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';
import avatar1 from '../../assets/img/Avatarback.svg';
import smokeImage from '../../assets/img/nebllina.svg';
import breakpoints from '../../styles/breakpoints';

// Animação de fumaça
const moveSmoke = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const moveAvatar = keyframes`
  0% {
    background-position: 180% 5%; /* Começa fora da tela, à esquerda */
  }
  100% {
    background-position: 100% 5%; /* Para na posição atual */
  }
`;

// Animação para o formulário aparecer suavemente
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.section`
  width: 100vw; /* Largura da tela disponível */
  height: 100vh; /* Altura da tela disponível */
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative; /* Necessário para o posicionamento da fumaça */
  margin: 0; /* Remove margens */
  padding: 0; /* Remove paddings */

  /* Fumaça */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: url(${smokeImage}) repeat-x;
    background-size: contain; /* Ajusta o tamanho da imagem */
    opacity: 0.8; /* Aumenta a opacidade para tornar a fumaça mais visível */
    animation: ${moveSmoke} 30s linear infinite;
    margin-top: 5%;
    z-index: 1;

    @media (max-width: 768px) {
      filter: brightness(2.2); /* Intensifica a cor da fumaça */
      z-index: 0; /* Coloca a fumaça atrás do formulário e da logo */
    }
  }
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
    z-index: 2;
  }
  .button {
    width: 19rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ isSmallScreen, isTablet }) => {
    if (isSmallScreen) return '100%';
    if (isTablet) return '120%';
    return '30%';
  }};
  max-width: ${({ isSmallScreen, isTablet }) => {
    if (isSmallScreen) return '90%';
    if (isTablet) return '90%';
    return '400px';
  }};
  margin: ${({ isSmallScreen, isTablet }) => {
    if (isSmallScreen) return 'auto -11%';
    if (isTablet) return 'auto -13%';
    return '15% -15%';
  }};
  margin-top: ${({ isSmallScreen, isTablet }) => {
    if (isSmallScreen) return '10%';
    if (isTablet) return '5%';
    return '8%';
  }};
  z-index: 2;
  animation: ${fadeIn} 3s ease-out;

  h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-family: 'MyFont';
  }
  .text-field {
    min-height: ${({ smallScreen }) => (smallScreen ? '50px' : '50px')};
    font-family: 'MyFont';
    position: relative;
    width: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '90%';
      if (isTablet) return '80%';
      return '87%';
    }};
    margin-top: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '-10%';
      if (isTablet) return '-10%';
      return '10%';
    }};

    & label {
      font-family: 'MyFont';
      font-size: ${({ smallScreen }) => (smallScreen ? '14px' : '18px')}; /* Ajusta o tamanho da fonte da label */
      top: ${({ smallScreen }) => (smallScreen ? '1px' : '1px')}; /* Ajusta a posição vertical da label */
      left: ${({ smallScreen }) => (smallScreen ? '0px' : '0px')}; /* Ajusta a posição horizontal da label */
      transition: 0.2s ease-out; /* Transição suave para a label */
    }

    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
    @media (max-width: 768px) {
      background-color: #fff; /* Cor de fundo branco */
    }

    @media (max-width: 3840px) {
      width: 100%;
    }
  }

  .text-field-password {
    margin-top: 3%;
    margin-bottom: 1%;
    font-family: 'MyFont';
    min-height: ${({ smallScreen }) => (smallScreen ? '40px' : '50px')};
    width: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '90%';
      if (isTablet) return '80%';
      return '87%';
    }};

    & label {
      font-family: 'MyFont';
      font-size: ${({ smallScreen }) => (smallScreen ? '14px' : '18px')}; /* Ajusta o tamanho da fonte da label */
      top: ${({ smallScreen }) => (smallScreen ? '1px' : '1px')}; /* Ajusta a posição vertical da label */
      left: ${({ smallScreen }) => (smallScreen ? '0px' : '0px')}; /* Ajusta a posição horizontal da label */
      transition: 0.2s ease-out; /* Transição suave para a label */
    }

    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
    @media (max-width: 768px) {
      background-color: #fff; /* Cor de fundo branco */
    }
    @media (max-width: 3840px) {
      width: 100%;
      margin-top: 2%;
    }
  }

  .recaptcha-container {
    min-height: ${({ smallScreen }) => (smallScreen ? '50px' : '50px')};
    font-family: 'MyFont';
    position: relative;
    width: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '100%';
      if (isTablet) return '100%';
      return '100%';
    }};
    @media (max-width: 3840px) {
      width: 100%;
      margin-top: 2%;
    }
  }

  button:not(.button-eye) {
    margin-top: 10px;
    width: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '90%';
      if (isTablet) return '80%';
      return '87%';
    }};
    color: white;
    min-height: ${({ smallScreen }) => (smallScreen ? '40px' : '50px')};
    font-size: ${({ smallScreen }) => (smallScreen ? '14px' : '15px')};
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
    @media (max-width: 1024px) {
      height: 0%;
    }
    @media (max-width: 1440px) {
      height: 0%;
    }
    @media (max-width: 2560px) {
      height: 0%;
    }
    @media (max-width: 3840px) {
      width: 100%;
      margin-top: 2%;
    }
  }

  p {
    font-family: 'MyFont';
    font-weight: 300;
    font-size: ${({ smallScreen }) => (smallScreen ? '310%' : '110%')};
  }

  p:hover {
    text-decoration: underline;
  }

  input {
    font-family: 'MyFont';
    height: ${({ smallScreen }) => (smallScreen ? '35px' : '40px')}; /* Ajusta a altura dos inputs */
    font-size: ${({ smallScreen }) => (smallScreen ? '16px' : '18px')}; /* Ajusta o tamanho da fonte dos inputs */
    padding: 0 10px;
    border-radius: 4px;
    max-width: 300px;
    margin: ${({ smallScreen }) => (smallScreen ? '10px' : '10px')}; /* Ajusta a margem dos inputs */
    width: 100%;
    max-width: 500px; /* Limite a largura máxima dos inputs */
  }

  select {
    width: 250px;
    height: 40px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 0%;
  }

  @media (max-width: 425px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 0%;
  }

  @media (max-width: 3840px) {
    width: 150%;
    margin-top: 10%;
  }

  .input-label {
    font-family: 'MyFont';
  }

  .enviar {
    margin-top: 5%;
    color: #ae1100;
    margin-left: -52%;
    min-height: ${({ smallScreen }) => (smallScreen ? '50px' : '50px')};
    font-family: 'MyFont';
    position: relative;

    @media (max-width: 1255px) {
      margin-left: -60%;
    }

    @media (max-width: 1394px) {
      margin-left: -60%;
    }

    @media (max-width: 1144px) {
      margin-left: -65%;
    }

    @media (max-width: 385px) {
      margin-left: -43%;
    }

    @media (max-width: 424px) {
      margin-left: -43%;
    }

    @media (max-width: 440px) {
      margin-left: -41%;
    }

    @media (max-width: 830px) {
      margin-left: -85%;
    }

    @media (max-width: 1024px) {
      margin-left: -85%;
    }

    @media (max-width: 344px) {
      margin-left: -41%;
    }

    @media (max-width: 412px) {
      margin-left: -41%;
    }

    @media (max-width: 1280px) {
      margin-left: -61%;
    }
  }

  .logo {
    width: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '80%';
      if (isTablet) return '80%';
      return '100%';
    }};
    height: auto;
    margin: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '-15%';
      if (isTablet) return '-15%';
      return '-35%';
    }};
    margin-top: ${({ isSmallScreen, isTablet }) => {
      if (isSmallScreen) return '5%';
      if (isTablet) return '10%';
      return '-20%';
    }};
    @media (max-width: 3840px) {
      width: 100%;
    }
  }
`;

export const LoginContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150vh;
  background-image: url(${avatar1});
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  margin-left: 25%;
  justify-content: flex-start;
  margin-top: -10%;
  animation: ${moveAvatar} 3s forwards;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: ${({ isSmallScreen }) => (isSmallScreen ? 'block' : 'none')};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 10px;
    background-size: cover;
    background-position: bottom;
    margin-left: 0;
    margin-top: -20%;
    height: auto;
    background-image: none;
  }

  @media (max-width: 425px) {
    .avatar-login {
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: bottom;
    }
  }

  @media ${breakpoints.xl} {
    background-position: bottom;
    margin-top: -50%;
  }

  @media ${breakpoints.lg} {
    background-position: bottom;
    margin-top: -50%;
    background-image: none;
  }

  @media (max-width: 1309px) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 10px;
    background-size: cover;
    background-position: bottom;
    margin-left: 0;
    margin-top: 10%;
    height: auto;
    background-image: none;
  }

  @media (max-width: 1920px) {
    background-size: 75%;
    margin-top: -10%;
  }

  @media (max-width: 3840px) {
    background-size: 90%;
    margin-top: -10%;
  }
`;
