import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';
import { Container } from '../../styles/GlobalStyles';
import { DocumentManagementContainer } from './styled';
import 'react-toastify/dist/ReactToastify.css';

function DocumentManagement() {
  const [documents, setDocuments] = useState({
    Politicas: [],
    Normas: [],
    Procedimentos: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newLink, setNewLink] = useState('');
  const [policyName, setPolicyName] = useState('');
  const cnpjCreator = useSelector((state) => state.auth.user.cnpj_enterprise);

  const getPolicies = async () => {
    try {
      const response = await axios.get('/policy');

      const userPolicies = response.data.filter((policy) => policy.cnpj === cnpjCreator);

      const categorizedDocs = {
        Politicas: [],
        Normas: [],
        Procedimentos: [],
      };

      userPolicies.forEach((policy) => {
        if (policy.category && categorizedDocs[policy.category]) {
          categorizedDocs[policy.category].push(policy);
        }
      });
      setDocuments(categorizedDocs);
    } catch (error) {
      console.error('Erro ao buscar políticas:', error);
      toast.error('Erro ao buscar políticas.');
    }
  };

  useEffect(() => {
    getPolicies();
  }, [cnpjCreator]);

  const addDocument = async () => {
    if (!selectedCategory || !newLink || !policyName) {
      toast.error('Por favor, preencha todos os campos.');
      return;
    }

    const policyData = {
      cnpj: cnpjCreator,
      name: policyName,
      link: newLink,
      category: selectedCategory,
    };

    try {
      const response = await axios.post('/policy', policyData);
      if (response.status === 200) {
        const updatedDocs = {
          ...documents,
          [selectedCategory]: [...documents[selectedCategory], response.data],
        };

        setDocuments(updatedDocs);

        setNewLink('');
        setPolicyName('');
        toast.success('Documento adicionado com sucesso!');
      } else {
        toast.error('Erro ao salvar o documento.');
      }
    } catch (error) {
      console.error('Erro ao adicionar documento:', error);
      toast.error('Erro ao salvar o documento. Tente novamente.');
    }
  };

  const deleteDocument = async (category, id) => {
    try {
      const response = await axios.delete(`/policy/${id}`, {
        data: { cnpj: cnpjCreator },
      });

      if (response.status === 200) {
        const updatedDocs = {
          ...documents,
          [category]: documents[category].filter((doc) => doc.id !== id),
        };

        setDocuments(updatedDocs);
        toast.success('Documento excluído com sucesso!');
      } else {
        toast.error('Erro ao excluir o documento.');
      }
    } catch (error) {
      console.error('Erro ao excluir documento:', error);
      toast.error('Erro ao deletar o documento. Tente novamente.');
    }
  };

  return (
    <Container>
      <DocumentManagementContainer>
        <h2>Área de Gestão de Documentos</h2>
        <div>
          <TextField
            className="text-field"
            select
            label="Selecione uma categoria"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="">Selecione</MenuItem>
            <MenuItem value="Politicas">Políticas</MenuItem>
            <MenuItem value="Normas">Normas</MenuItem>
            <MenuItem value="Procedimentos">Procedimentos</MenuItem>
          </TextField>

          <TextField
            className="text-field"
            label="Nome da política"
            type="text"
            value={policyName}
            onChange={(e) => setPolicyName(e.target.value)}
            variant="outlined"
            fullWidth
          />

          <TextField
            className="text-field"
            label="Link do documento"
            type="text"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            variant="outlined"
            fullWidth
          />

          <Button className="button" variant="contained" color="primary" onClick={addDocument}>
            Adicionar Link
          </Button>
        </div>

        {['Politicas', 'Normas', 'Procedimentos'].map((category) => (
          <div key={category}>
            <h3>{category}</h3>
            <ul>
              {documents[category].length > 0 ? (
                documents[category].map((doc) => (
                  <li key={doc.id}>
                    <a href={doc.link} target="_blank" rel="noopener noreferrer">
                      {doc.name || doc.link}
                    </a>
                    <IconButton aria-label="deletar" onClick={() => deleteDocument(category, doc.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))
              ) : (
                <p>Não há documentos nesta categoria.</p>
              )}
            </ul>
          </div>
        ))}
      </DocumentManagementContainer>
      <ToastContainer />
    </Container>
  );
}

export default DocumentManagement;
