import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiIconButton from '@mui/material/IconButton';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import axios from '../../services/axios';
import { Form, StyledContainer, StyledSection, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

// Estilizando o botão de remover alternativa
const StyledDeleteButton = styled(MuiIconButton)`
  color: red; /* Cor do ícone */
  &:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Cor de fundo ao passar o mouse */
  }
  margin-left: 8px; /* Espaço à esquerda do botão */
`;

// Estilizando o botão de adicionar alternativa
const StyledAddAnswerButton = styled.button`
  color: white; /* Cor do texto */
  border: none; /* Sem borda */
  padding: 8px 16px; /* Espaçamento interno */
  border-radius: 5px; /* Bordas arredondadas */
  cursor: pointer; /* Cursor de mão */
  margin-top: 10px; /* Margem acima do botão */
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  h2 {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 425px) {
    width: 80vw;
  }
`;

export const Button = styled.button`
  margin: 10px;
  padding: 10px 85px;
  background-color: #ae1100;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: #ae1100;
  }

  &:first-of-type {
    background-color: #ae1100; /* Verde para o botão de confirmar */
  }

  &:last-of-type {
    background-color: #ae1100; /* Vermelho para o botão de cancelar */
  }
`;

export default function SubmoduleEdit({ id, history, ...props }) {
  const dispatch = useDispatch();
  const QuizId = id;
  const [quizzes, setQuiz] = useState({ questions: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false); // Estado para controle do modal de confirmação
  const [deleteAction, setDeleteAction] = useState(null); // Armazena a ação de deletar

  useEffect(() => {
    if (!QuizId) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/quiz/${QuizId}`);
        const questions = JSON.parse(data.questions);
        if (!Array.isArray(questions)) {
          const quest = JSON.parse(questions);
          setQuiz({ questions: quest, maxScore: data.maxScore });
          return;
        }
        setQuiz({ questions, maxScore: data.maxScore });
      } catch (err) {
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);
        if (status === 400) errors.map((error) => toast.error(error));
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [QuizId, history]);

  // Funções para controle do modal de confirmação
  const handleConfirmOpen = (action) => {
    setDeleteAction(() => action);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setDeleteAction(null);
  };

  const handleDelete = () => {
    if (deleteAction) {
      deleteAction(); // Executa a ação de deletar
    }
    handleConfirmClose(); // Fecha o modal
  };

  // Função para remover pergunta
  const handleRemoveQuestion = (questionIndex) => {
    handleConfirmOpen(() => {
      const newQuizzes = { ...quizzes };
      newQuizzes.questions.splice(questionIndex, 1);
      setQuiz(newQuizzes);
    });
  };

  // Função para remover alternativa
  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    handleConfirmOpen(() => {
      const newQuizzes = { ...quizzes };
      newQuizzes.questions[questionIndex].answers.splice(answerIndex, 1);
      setQuiz(newQuizzes);
    });
  };

  const handleQuestionChange = (e, questionIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].questionText = e.target.value;
    setQuiz(newQuizzes);
  };

  const handleAnswerChange = (e, questionIndex, answerIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].answers[answerIndex].text = e.target.value;
    setQuiz(newQuizzes);
  };

  const handleCorrectChange = (e, questionIndex, answerIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].answers[answerIndex].correct = e.target.checked;
    setQuiz(newQuizzes);
  };

  const handleAddQuestion = () => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions.push({
      id: uuidv4(),
      questionText: '',
      answers: [{ id: uuidv4(), text: '', correct: false }],
    });
    setQuiz(newQuizzes);
  };

  const handleAddAnswer = (questionIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].answers.push({
      id: uuidv4(),
      text: '',
      correct: false,
    });
    setQuiz(newQuizzes);
  };

  const handleScoreChange = (e) => {
    const newMaxScore = e.target.value;
    setQuiz((prev) => ({ ...prev, maxScore: newMaxScore }));
  };

  const handleSubmit = async (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    try {
      setIsLoading(true);
      dispatch(
        actions.editQuizRequest({
          history,
          id,
          questions: quizzes.questions,
          maxScore: quizzes.maxScore,
        }),
      );
    } catch (err) {
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);
      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      }
      if (status === 401) dispatch(actions.loginFailure());
    } finally {
      setIsLoading(false);
    }
    handleClose();
  };

  return (
    <Bloco>
      <MuiIconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={props.handleClose}>
        <CloseIcon />
      </MuiIconButton>
      <Loading isLoading={isLoading} />
      <StyledContainer>
        <StyledSection>
          <Form onSubmit={handleSubmit}>
            <div className="box-container">
              <h1>Editar Avaliação</h1>
              <h4>Atualize o conteúdo da Avaliação</h4>

              {Array.isArray(quizzes.questions) &&
                quizzes.questions.map((question, questionIndex) => (
                  <div key={question.id}>
                    <h2>Questão:</h2>
                    <input
                      id={`question-${questionIndex}`}
                      type="text"
                      value={question.questionText}
                      onChange={(e) => handleQuestionChange(e, questionIndex)}
                    />
                    {question.answers &&
                      question.answers.map((answer, answerIndex) => (
                        <div key={answer.id}>
                          <h5>Alternativa:</h5>
                          <input
                            id={`answer-${questionIndex}-${answerIndex}`}
                            type="text"
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(e, questionIndex, answerIndex)}
                          />
                          <input
                            className="checkbox"
                            id={`checkbox-${questionIndex}-${answerIndex}`}
                            type="checkbox"
                            style={{ marginTop: '20px' }}
                            checked={answer.correct}
                            onChange={(e) => handleCorrectChange(e, questionIndex, answerIndex)}
                          />
                          <StyledDeleteButton
                            onClick={() => handleRemoveAnswer(questionIndex, answerIndex)}
                            aria-label="Remover Alternativa"
                            style={{ marginTop: '-35px' }}
                          >
                            <DeleteIcon />
                          </StyledDeleteButton>
                        </div>
                      ))}
                    <StyledAddAnswerButton
                      className="button"
                      type="button"
                      onClick={() => handleAddAnswer(questionIndex)}
                      aria-label="Adicionar Alternativa"
                    >
                      Adicionar Alternativa
                    </StyledAddAnswerButton>
                    <button className="button" type="button" onClick={() => handleRemoveQuestion(questionIndex)}>
                      Remover Pergunta
                    </button>
                  </div>
                ))}

              <StyledAddAnswerButton
                className="button"
                type="button"
                onClick={handleAddQuestion}
                aria-label="Adicionar Questão"
              >
                Adicionar Questão
              </StyledAddAnswerButton>

              <input type="number" value={quizzes.maxScore} onChange={handleScoreChange} min="0" max="100" />

              <button className="button" type="submit">
                Salvar
              </button>
            </div>
            <Modal open={confirmOpen} onClose={handleConfirmClose}>
              <ModalContainer>
                <h2>Você tem certeza que deseja remover esta alternativa?</h2>
                <Button type="button" onClick={handleDelete}>
                  Confirmar
                </Button>
                <Button type="button" onClick={handleConfirmClose}>
                  Cancelar
                </Button>
              </ModalContainer>
            </Modal>
          </Form>
        </StyledSection>
      </StyledContainer>
    </Bloco>
  );
}

SubmoduleEdit.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};
