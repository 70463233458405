import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { Popup } from './styled';
import axios from '../../services/axios';

export default function UserPopup({ userList, courseId, onClose }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // Estado para controlar a sele....o de todos

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      }
      return [...prevSelectedUsers, userId];
    });
  };

  // Fun....o para selecionar ou desmarcar todos
  const handleSelectAllUsers = () => {
    if (selectAll) {
      // Se todos j.. est..o selecionados, desmarca todos
      setSelectedUsers([]);
    } else {
      // Seleciona todos os IDs dos usu..rios
      setSelectedUsers(userList.map((user) => user.id));
    }
    setSelectAll(!selectAll); // Alterna entre selecionar e desmarcar todos
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        selectedUsers.map((userId) =>
          axios.post('/usercourse', {
            userId,
            courseId,
            hasAccess: false,
            completionPercentage: 0,
          }),
        ),
      );
      onClose(); // Fecha o modal ap..s salvar
      window.location.reload(); // Atualiza a p..gina
    } catch (error) {
      console.error(error);
    }
  };

  const handleExit = () => {
    onClose(); // Fecha o modal quando "Voltar" .. clicado
  };

  const isUserListEmpty = userList.length === 0;

  return (
    <Popup>
      <FaTimes className="Button" onClick={handleExit} /> {/* Fechar modal ao clicar no ..cone */}
      <div className="box-container">
        <h1>Lista de Usu..rios</h1>
        {isUserListEmpty ? (
          <h3>Não há usuários disponíveis para permissões no momento.</h3>
        ) : (
          <>
            {/* Checkbox para selecionar ou desmarcar todos */}
            <div className="user-item">
              <span className="span">Selecionar Todos</span>
              <input type="checkbox" checked={selectAll} onChange={handleSelectAllUsers} />
            </div>

            {/* Lista de usu..rios com checkbox */}
            {userList.map((user) => (
              <div key={user.id} className="user-item">
                <span className="span">
                  {user.nome} {user.sobrenome}
                </span>
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleSelectUser(user.id)}
                />
              </div>
            ))}
          </>
        )}
        <button type="button" onClick={isUserListEmpty ? handleExit : handleSave}>
          {isUserListEmpty ? 'Voltar' : 'Salvar'}
        </button>
      </div>
    </Popup>
  );
}

UserPopup.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nome: PropTypes.string.isRequired,
      sobrenome: PropTypes.string.isRequired,
    }),
  ).isRequired,
  courseId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
