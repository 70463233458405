import styled from 'styled-components';
import Card from '@mui/material/Card';
import breakpoints from '../../styles/breakpoints';

export const CollaboratorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5%;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-top: 0%;
  .link {
    text-decoration: none;
    color: inherit;
  }
  .h1 {
    margin-bottom: 5%;
    font-size: 20px; /* 1.6 vezes o tamanho da fonte base */
    font-weight: 900; /* Torna o texto bem grosso */
    color: #000;
  }
  span {
    font-weight: 600;
    color: #000;
    object-fit: contain;
    min-height: 50px;
    margin: 5px 0px;
    align-items: center;
    @media (min-width: 1440px) {
      font-size: 1;
    }
  }
  svg {
    width: 25vw;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;

    @media (min-width: 1440px) {
      height: 120px;
    }
  }
  @media (min-width: 1440px) {
    grid-gap: 80px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (min-width: 1024px) {
    grid-gap: 80px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .svg {
    width: 100%; /* Ajuste a largura da imagem */
    height: 100%;
    margin-bottom: 2%;
    z-index: 1;
  }
`;

export const StyledCard = styled(Card)`
  width: 310px; /* Diminuir a largura do card */
  height: 400px; /* Definir uma altura fixa para o card */
  margin: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  margin-bottom: -5%;
  justify-content: space-between; /* Distribuir o espaço verticalmente */
  margin-top: 30%;
  margin-left: 50%;

  @media ${breakpoints.md} {
    height: 40%;
    width: 70%;
  }

  @media ${breakpoints.mg} {
    height: 40%;
    width: 70%;
  }

  @media (max-width: 1360px) {
    height: 40%;
    width: 70%;
    margin-bottom: -55%;
  }

  @media (max-width: 1125px) {
    height: 40%;
    width: 70%;
    margin-bottom: -85%;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px #ae1100;
  }

  .MuiCardMedia-root {
    height: 150px;
  }

  img {
    width: 250%; /* Ajuste a largura da imagem */
    height: 250%;
    margin-bottom: 12%;
    z-index: 1;
    object-fit: contain; /* Para garantir que a imagem se ajuste sem cortar */
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: -18%;
    margin-bottom: 18px; /* Ajustar a margem inferior para separar do botão */
    overflow-y: auto; /* Adicionar rolagem vertical */
    max-height: 300px; /* Definir altura máxima para o conteúdo */

    /* Ocultar barras de rolagem no navegador Webkit */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    /* Ocultar barras de rolagem no navegador Firefox */
    scrollbar-width: none;
  }

  .MuiTypography-root {
    margin-top: 8px;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
  }

  .MuiCardActions-root {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    margin-top: auto; /* Empurra o botão para o final do card */
  }

  button.report-button {
    width: 100%;
    height: 40px; /* Definir uma altura fixa */
    max-width: 380px; /* Definir uma largura máxima consistente */
    padding: 5%; /* Remover padding para controlar o tamanho */
    font-size: 13px;
    color: white;
    background-color: #ae1100;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-family: 'MyFont';
    font-weight: bold;
    text-transform: none;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex; /* Para centralizar conteúdo interno */
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #d43210;
    }

    /* Para ajustar o ícone no botão */
    .MuiButton-startIcon {
      margin: 0; /* Remove margem do ícone */
    }
  }
  .h1 {
    margin-bottom: 5%;
    font-size: 15px;
    font-weight: 900px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #000; /* Linha abaixo do título */
    padding-bottom: 8px; /* Espaço entre o título e a linha */
  }

  .button {
    width: 130%;
    max-width: 130%;
    padding: 5px;
    font-size: 13px;
    color: white;
    background-color: #ae1100;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-family: 'MyFont';
    font-weight: bold;
    text-transform: none;
    line-height: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #d43210;
    }

    /* Responsividade para telas menores */
    @media (max-width: 768px) {
      max-width: 100%;
      font-size: 12px;
    }

    @media (max-width: 1920px) {
      max-width: 85%;
      padding: 5px;
      margin-left: 7%;
      font-size: 11px;
    }
    @media (max-width: 1742px) {
      max-width: 80%;
      padding: 5px;
      margin-left: 10%;
      font-size: 11px;
    }
    @media (max-width: 1631px) {
      max-width: 75%;
      padding: 5px;
      margin-left: 12%;
      font-size: 11px;
    }
    @media (max-width: 1564px) {
      max-width: 70%;
      padding: 5px;
      margin-left: 15%;
      font-size: 11px;
    }
    @media (max-width: 1453px) {
      max-width: 65%;
      padding: 5px;
      margin-left: 18%;
      font-size: 11px;
    }
    @media (max-width: 1367px) {
      max-width: 60%;
      padding: 5px;
      margin-left: 20%;
      font-size: 11px;
    }
    @media (max-width: 1284px) {
      max-width: 55%;
      padding: 5px;
      margin-left: 23%;
      font-size: 11px;
    }
    @media (max-width: 1227px) {
      max-width: 50%;
      padding: 5px;
      margin-left: 25%;
      font-size: 11px;
    }
    @media (max-width: 1065px) {
      max-width: 42%;
      padding: 5px;
      margin-left: 29%;
      font-size: 11px;
    }
  }
`;
