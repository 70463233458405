import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { CardContent, Typography, Divider } from '@material-ui/core';
import axios from '../../services/axios';
import { Container } from '../../styles/GlobalStyles';
import { DocumentViewerContainer, StyledCard } from './styled';
import 'react-toastify/dist/ReactToastify.css';

function DocumentViewer() {
  const [documents, setDocuments] = useState([]);
  const cnpjCreator = useSelector((state) => state.auth.user.cnpj_enterprise);

  const getDocuments = async () => {
    try {
      const response = await axios.get('/policy');
      const userDocuments = response.data.filter((doc) => doc.cnpj === cnpjCreator);
      console.log('Documentos filtrados:', userDocuments);

      setDocuments(userDocuments);
    } catch (error) {
      console.error('Erro ao buscar documentos:', error);
      toast.error('Erro ao buscar documentos.');
    }
  };

  useEffect(() => {
    getDocuments();
  }, [cnpjCreator]);

  const groupedByCategory = documents.reduce((acc, doc) => {
    if (!acc[doc.category]) {
      acc[doc.category] = [];
    }
    acc[doc.category].push(doc);
    return acc;
  }, {});

  return (
    <Container>
      <DocumentViewerContainer>
        <h2>Gestão de Documentos</h2>
        <h4>Clique no cartão para visualizar os documentos</h4>
        <div>
          {Object.keys(groupedByCategory).length > 0 ? (
            Object.keys(groupedByCategory).map((category) => (
              <div key={category}>
                <Typography
                  variant="h5"
                  style={{ marginTop: '20px', marginBottom: '10px', fontFamily: 'MyFont', fontSize: '24px' }}
                >
                  {category}
                </Typography>

                <Divider style={{ marginBottom: '20px' }} />

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                  {groupedByCategory[category].map((doc) => (
                    <StyledCard key={doc.id} as="a" href={doc.link} target="_blank" rel="noopener noreferrer">
                      <CardContent>
                        <Typography variant="h6" component="h3">
                          {' '}
                          {doc.name || doc.link}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                          <a href={doc.link} target="_blank" rel="noopener noreferrer">
                            {doc.link}
                          </a>
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>Não há documentos disponíveis.</p>
          )}
        </div>
      </DocumentViewerContainer>
      <ToastContainer />
    </Container>
  );
}

export default DocumentViewer;
