import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import UserPopup from '../../components/Popup';
import Loading from '../../components/Loading';

export default function Course({ match }) {
  const id = get(match, 'params.id', '');
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [accessWithUserNames, setAccessWithUserNames] = useState([]);

  useEffect(() => {
    if (!Array.isArray(users)) return;

    const newAccess = access.map((acesso) => {
      const foundUser = users.find((user) => user.id === acesso.userId);
      return { ...acesso, userName: foundUser ? foundUser.nome : '' };
    });
    setAccessWithUserNames(newAccess);
  }, [access, users]);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await axios.get(`/usercourse`);
      const acesso = response.data.filter((UserCourse) => UserCourse.courseId && UserCourse.courseId === Number(id));
      setAccess(acesso);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/rhusers');
        const empresaUsers = response.data.filter(
          (user) => user.departamento && user.departamento.toLowerCase() === 'empresa',
        );
        setUsers(empresaUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const handleToggleAccess = async (userCourseId, hasAccess) => {
    try {
      const response = await axios.get(`/usercourse/${userCourseId}`);
      const colaboradores = await axios.get(`/rhusers/`);
      const selecionador = colaboradores.data.filter((colaborador) => colaborador.creatorId === response.data.userId);
      console.log(selecionador);
      if (response.status === 200) {
        // Atualizar o acesso do usu..rio espec..fico na tabela usercourse
        await axios.put(`/usercourse/${userCourseId}`, { hasAccess: !hasAccess });

        // Buscar todos os usercourse que t..m o courseId selecionado
        const userCoursesResponse = await axios.get(`/usercourse?courseId=${response.data.courseId}`);
        const userCourses = userCoursesResponse.data;

        // Filtrar esses usercourse para encontrar aqueles cujo userId corresponde ao id do selecionador e cujo courseId corresponde ao courseId selecionado
        const userCoursesToUpdate = userCourses.filter(
          (userCourse) =>
            selecionador.some((user) => user.id === userCourse.userId) &&
            userCourse.courseId === response.data.courseId,
        );

        // Atualizar o acesso de todos esses usercourse
        const updatePromises = userCoursesToUpdate.map((userCourse) =>
          axios.put(`/usercourse/${userCourse.id}`, { hasAccess: !hasAccess }),
        );
        await Promise.all(updatePromises);

        // Recarregar os dados ap..s a atualiza....o
        fetchData();
      } else {
        console.error(`Curso de usu..rio com id ${userCourseId} n..o encontrado`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1>Permissões</h1>
        <h4>Selecione empresa para ter acesso ao cursos</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell className="TableCell" style={{ paddingLeft: '480px' }}>
                Permitir / bloquear
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessWithUserNames
              .filter((acesso) => acesso.userName) // Filtra os acessos que t..m um userName
              .map((acesso) => (
                <TableRow key={acesso.id}>
                  <TableCell className="user-cell">{acesso.userName}</TableCell>
                  <TableCell>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={acesso.hasAccess}
                      onChange={() => handleToggleAccess(acesso.id, acesso.hasAccess)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isPopupOpen && (
          <UserPopup
            userList={users.filter((user) => !access.some((acesso) => acesso.userId === user.id))}
            courseId={id}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
        <div>
          <button type="button" onClick={() => setIsPopupOpen(true)}>
            Criar novo acesso
          </button>
        </div>
      </CollaboratorContainer>
    </Container>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
